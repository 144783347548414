export default [
    {
        name: "Stereotypical Bias in Pretrained Language Models",
        description: "An analysis of the robustness of Large Language Models (LLMs) to stereotypical bias.",
        language: "Python | LLMs | Responsible AI",
        link: "https://github.com/takavor/Stereotypical-Bias-in-Pretrained-Language-Models",
        github: true
    },
    {
        name: "Book Recommendation Web App",
        description: "Created a book recommendation web app using collaborative filtering and clustering.",
        language: "Python | Streamlit | scikit-learn | KNN",
        link: "https://github.com/takavor/Recommendation-System",
        github: true
    },
    {
        name: "Stock Market Predictor with AWS",
        description: "Created an XGBoost stock market predictor model deployed with AWS services.",
        language: "Python | XGBoost | AWS | SageMaker | Lambda",
        link: "https://github.com/takavor/Stock-Market-Predictor-with-AWS",
        github: true
    },
    {
        name: "Armenian Dialect Detector",
        description: "Trained different ML models to determine which dialect Armenian texts correspond to with great performance.",
        language: "Python | Natural Language Processing",
        link: "https://github.com/takavor/Armenian-Dialect-Detector",
        github: true
    },
    {
        name: "Classification of Image Data",
        description: "Implemented multilayer perceptron and tested performance on the Fastion-MNIST dataset.",
        language: "Python | MLP",
        link: "https://github.com/takavor/MLP-Image-Data-Classification",
        github: true
    },
    {
        name: "BERT Implementation",
        description: "Implemented Bidirectional Encoder Representations from Transformers (BERT).",
        language: "Python | BERT",
        link: "https://github.com/mikemikezhu/BERT_IMDB",
        github: true
    },
    {
        name: "Classification of IMDb Reviews",
        description: "Implemented logistic & multiclass regression and tested performance on IMDb movie reviews dataset.",
        language: "Python | Logistic Regression",
        link: "https://github.com/takavor/Classification-of-IMDb-Reviews",
        github: true
    },
    {
        name: "KNN & Decision Tree Implementation",
        description: "Implemented KNN and & Decision Tree machine learning algorithms.",
        language: "Python | KNN | Decision Tree",
        link: "https://github.com/takavor/Implementing-KNN-DT",
        github: true
    }
]